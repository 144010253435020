<template>
  <div class="view-container">
    <router-link to="/add-user">Toevoegen</router-link>
    <router-link
      v-for="user in users"
      :key="user.id"
      class="user"
      :to="`/user/${user.id}`"
    >{{user.username}}</router-link>
  </div>
</template>

<script>
export default {
  data () {
    return {
      users: []
    }
  },
  mounted () {
    this.$store.dispatch('getUsers').then(({ data }) => {
      this.users = data
    })
  }
}
</script>

<style lang="scss" scoped>
.user {
  border-bottom: 1px solid #333;
  cursor: pointer;
  padding: 16px 0;

  &:hover {
    background: #eee;
  }
}
</style>