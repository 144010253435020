<template>
  <div class="view-container">
    <div class="owner-name" v-if="recipe.owner">
      Aangemaakt door: {{recipe.owner.name ? recipe.owner.name : recipe.owner.username}}
    </div>
    <div v-else>
      <div class="claim-button" @click="claimRecipe">Claim recept</div>
    </div>
    <RecipeForm @submit="submit" :recipe="recipe" />
  </div>
</template>

<script>
import RecipeForm from '../components/recipeForm'

export default {
  data () {
    return {
      recipe: {}
    }
  },
  computed: {
    recipeId() {
      return this.$route.params.id
    }
  },
  components: {
    RecipeForm
  },
  methods: {
    async submit() {
      await this.$store.dispatch('updateRecipe', this.recipe)
      this.$router.push('/')
    },
    async claimRecipe() {
      try {
        const newOwner = await this.$store.dispatch('claimRecipe', this.recipe)

        this.recipe.owner = newOwner
        
        this.flashMessage.success({
          title: 'Gelukt!',
          message: 'Het recept is succesvol geclaimed.',
          blockClass: 'flash_message'
        });
      } catch(err) {
        this.flashMessage.error({
          title: 'Er is iets verkeerd gegaan',
          message: err,
          blockClass: 'flash_message'
        });

        throw err
      }
    }
  },
  async mounted() {
    if(this.recipeId) {
      const recipe = await this.$store.dispatch('getRecipeById', this.recipeId)

      if(recipe) {
        this.recipe = recipe
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .owner-name {
    color: #666;
    font-style: italic;
    margin-bottom: 16px;
  }
  .claim-button {
    padding: 8px 0px;
    color: darken(#9dc763, 10);
    text-decoration: underline;
    display: inline-block;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
  }
</style>