<template>
  <div class="view-container">
    <UserForm
      :user="user"
      @submit="submitUser"
    />
  </div>
</template>

<script>
import UserForm from '../components/userForm'
import User from '../user.class'

export default {
  data() {
    return {
      user: {}
    }
  },
  components: {UserForm},
  methods: {
    submitUser() {
      this.$store.dispatch('createUser', this.user)
    }
  },
  beforeCreated() {
    this.user = new User()
  }
}
</script>

<style>

</style>